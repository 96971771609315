import "./App.css";
import { Route, Routes } from "react-router-dom";
// import ForgetPass from "pages/ForgetPass";
import Forbidden from "pages/Forbidden";
import RequireAuth from "setup/requireAuth";
//import Dashboard from "pages/Dashboard";

import VendorList from "pages/VendorList/VendorList";
import CreateVendor from "pages/CreateVendor/CreateVendor";
import Login from "pages/Login/Login";
import AdminUserMan from "pages/UserManagement/Usermanagement";
import LandingPage from "pages/LandingPage/LandingPage";
import VendorView from "pages/VendorView/VendorView";
import LMISLearnerProfile from "pages/LMISLearnerProfile/LMISLearnerProfile";
import Signup from "pages/SignUp/SignUp";
import ForgotPassword from "pages/ForgotPass";
import CalendarPage from "pages/calender/calender";

function App() {
  return (
    <Routes>
      {/* Public routes */}
      {/* <Route path="/Login" element={<Login />} /> */}
      <Route index element={<Login />} />

      <Route path="/SignUp" element={<Signup />} />

      {/* <Route path="/sign-up" element={<Signup />} /> */}
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/403" element={<Forbidden />} />

      <Route element={<RequireAuth />}>
        {/* delegate */}
        <Route path="/VendorList" element={<VendorList />} />
        {/* <Route path="/VendorList" element={<VendorList />} /> */}
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route path="/vendorview/:id" element={<VendorView />} />
        <Route path="/vendorview/:id/:id2" element={<LMISLearnerProfile />} />

        <Route path="/CreateVendor" element={<CreateVendor />} />
        <Route path="/UserManagement" element={<AdminUserMan />} />
        <Route path="/calender" element={<CalendarPage />} />
        {/* <Route path="/payment-profile" element={<ProfilePayement />} />
      <Route path="/travel-profile" element={<ProfileTravel />} />
      <Route path="/contract-profile" element={<ProfileContract />} /> */}
      </Route>

      {/* <Route element = {<RequireAuth allowedRoles = {['2009']}/>}> */}
      {/* admin */}
      {/* <Route path="/admin-dashboard" element={<AdminDash />} />
      <Route path="/admin-database" element={<AdminDB />} />
      <Route path="/notifications" element={<Notifications />} />  */}
      {/* <Route path="/admin-database" element={<AdminDB />} />
      <Route path="/admin-user-management" element={<AdminUserMan />} /> */}

      {/* </Route> */}
      {/* catch all */}
      {/* <Route path='*' element = {<missing/>}/> */}
    </Routes>
  );
}

export default App;
