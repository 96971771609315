import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  IconButton,
  InputAdornment,
  Chip,
  CircularProgress,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./CalendarModal.css"; // Create this CSS file

const CalendarModal = ({
  open,
  onClose,
  newEvent,
  setNewEvent,
  onEventAdd,
  isEditing,
}) => {
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
    setEmailError("");
  };

  const handleAddEmail = async () => {
    const trimmedEmail = emailInput.trim();

    if (!trimmedEmail) return;

    setIsValidating(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (!isValidEmail(trimmedEmail)) {
        setEmailError("Please enter a valid email address");
        return;
      }

      const currentEmails = Array.isArray(newEvent.email) ? newEvent.email : [];

      if (currentEmails.includes(trimmedEmail)) {
        setEmailError("Email already added");
        return;
      }

      const updatedEmails = [...currentEmails, trimmedEmail];
      setNewEvent({ ...newEvent, email: updatedEmails });
      setEmailInput("");
      setEmailError("");
    } catch (error) {
      setEmailError("Failed to validate email");
    } finally {
      setIsValidating(false);
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    const currentEmails = Array.isArray(newEvent.email) ? newEvent.email : [];
    const updatedEmails = currentEmails.filter(
      (email) => email !== emailToDelete
    );
    setNewEvent({ ...newEvent, email: updatedEmails });
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleAddEmail();
    }
  };

  const handleChange = (field) => (event) => {
    setNewEvent({ ...newEvent, [field]: event.target.value });
    if (formErrors[field]) {
      setFormErrors((prev) => ({ ...prev, [field]: null }));
    }
  };

  const handleTimeChange = (field) => (newTime) => {
    setNewEvent({ ...newEvent, [field]: newTime });
  };

  const validateForm = () => {
    const errors = {};

    if (!newEvent.title || newEvent.title.trim() === "") {
      errors.title = "Event title is required";
    }

    if (
      !newEvent.email ||
      !Array.isArray(newEvent.email) ||
      newEvent.email.length === 0
    ) {
      errors.email = "At least one email is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveEvent = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setIsSaving(true);
      await onEventAdd();
    } catch (error) {
      console.error("Failed to save event:", error);
      setFormErrors((prev) => ({ ...prev, submit: "Failed to save event" }));
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setEmailInput("");
    setEmailError("");
    setFormErrors({});
    setIsValidating(false);
    setIsSaving(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className="calendar-modal">
      <Box className="modal-container">
        <Typography className="modal-title">
          {isEditing ? "Edit Event" : "Add New Event"}
        </Typography>

        <TextField
          fullWidth
          label="Event Title"
          variant="outlined"
          value={newEvent.title || ""}
          onChange={handleChange("title")}
          className="form-field"
          error={!!formErrors.title}
          helperText={formErrors.title}
          required
        />

        <TextField
          fullWidth
          label="Location"
          variant="outlined"
          value={newEvent.location || ""}
          onChange={handleChange("location")}
          className="form-field"
        />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            className="form-field time-picker"
            label="Time Begin"
            value={newEvent.timeBegin}
            onChange={handleTimeChange("timeBegin")}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Time End"
            value={newEvent.timeEnd}
            className="form-field time-picker"
            onChange={handleTimeChange("timeEnd")}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <div className="email-input-container">
          <TextField
            fullWidth
            label="Add Email"
            variant="outlined"
            value={emailInput}
            onChange={handleEmailInputChange}
            onKeyPress={handleKeyPress}
            error={!!emailError || !!formErrors.email}
            helperText={emailError || formErrors.email}
            className="form-field email-input"
            disabled={isValidating}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isValidating ? (
                    <CircularProgress size={24} className="email-loading" />
                  ) : (
                    <IconButton
                      onClick={handleAddEmail}
                      disabled={isValidating}
                      className="add-email-button">
                      <AddCircleOutlineIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {newEvent.email && Array.isArray(newEvent.email) && (
            <div className="email-chips-container">
              {newEvent.email.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onDelete={() => handleDeleteEmail(email)}
                  className="email-chip"
                />
              ))}
            </div>
          )}
        </div>

        {formErrors.submit && (
          <Typography color="error" className="error-message">
            {formErrors.submit}
          </Typography>
        )}

        <FormControl fullWidth className="form-field select-field">
          <InputLabel id="repeat-label">Repeat</InputLabel>
          <Select
            labelId="repeat-label"
            value={newEvent.repeat || "never"}
            label="Repeat"
            onChange={handleChange("repeat")}>
            <MenuItem value="never">Never</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Comment"
          variant="outlined"
          multiline
          rows={4}
          value={newEvent.comment || ""}
          onChange={handleChange("comment")}
          className="form-field comment-field"
        />

        <div className="button-container">
          <Button
            onClick={handleClose}
            className="action-button cancel-button"
            variant="outlined"
            disabled={isSaving}>
            Cancel
          </Button>
          <Button
            onClick={handleSaveEvent}
            variant="contained"
            color="primary"
            className="action-button submit-button"
            disabled={isSaving}>
            {isSaving ? (
              <div className="loading-button-content">
                <CircularProgress size={20} color="inherit" />
                <span>{isEditing ? "Saving..." : "Adding..."}</span>
              </div>
            ) : isEditing ? (
              "Save"
            ) : (
              "Add Event"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
