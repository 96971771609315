import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarModal from "../../components/calenderComponent/calenderModal";
import EventDetailsModal from "../../components/calenderComponent/eventDetailsModal";
import { saveOrUpdateEvent } from "../../setup/DBWrites";
import { fetchEvents } from "../../setup/DbCalls";

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: null,
    end: null,
    location: "",
    timeBegin: null,
    timeEnd: null,
    email: "",
    repeat: "never",
    comment: "",
  });
  const [isDragging, setIsDragging] = useState(false);
  const emptyEvent = {
    title: "",
    start: null,
    end: null,
    location: "",
    timeBegin: null,
    timeEnd: null,
    email: "",
    repeat: "never",
    comment: "",
  };
  useEffect(() => {
    const loadEvents = async () => {
      try {
        const fetchedEvents = await fetchEvents();
        console.log("Fetched events:", fetchedEvents);
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error loading events:", error);
      }
    };

    loadEvents();
  }, []);

  const handleSelectSlot = ({ start, end }) => {
    setNewEvent({
      ...newEvent,
      start,
      end,
      timeBegin: moment(start),
      timeEnd: moment(end),
    });
    setShowModal(true);
  };

  const handleEventAdd = async () => {
    if (newEvent.title && newEvent.email) {
      try {
        const eventToSave = {
          ...newEvent,
          email: Array.isArray(newEvent.email)
            ? newEvent.email
            : newEvent.email.split(";"),
        };

        await saveOrUpdateEvent(eventToSave);
        setShowModal(false);
        setNewEvent(emptyEvent);
        const fetchedEvents = await fetchEvents();
        console.log("Fetched events:", fetchedEvents);
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error saving event:", error);
      }
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowEventDetailsModal(true);
  };

  const handleEditEvent = () => {
    setNewEvent({
      ...selectedEvent,
      timeBegin: moment(selectedEvent.start),
      timeEnd: moment(selectedEvent.end),
    });
    setShowEventDetailsModal(false);
    setShowModal(true);
  };

  const handleDeleteEvent = async () => {
    const updatedEvent = { ...selectedEvent, active: false };
    console.log("Marking event as inactive:", updatedEvent);
    await saveOrUpdateEvent(updatedEvent);

    const updatedEvents = events.map((event) =>
      event.id === selectedEvent.id ? { ...event, active: false } : event
    );
    console.log("Updated events state after deletion:", updatedEvents);
    setEvents(updatedEvents);
    setShowEventDetailsModal(false);
  };

  const handleEventDrop = async ({ event, start, end }) => {
    const updatedEvent = {
      ...event,
      start: start,
      end: end,
    };

    try {
      await saveOrUpdateEvent(updatedEvent);
      const updatedEvents = await fetchEvents();
      setEvents(updatedEvents);
    } catch (error) {
      console.error("Error updating event time:", error);
    }
  };

  return (
    <div style={{ height: "100vh", padding: "20px" }}>
      <Calendar
        localizer={localizer}
        events={events.filter((event) => event.active)}
        startAccessor="start"
        endAccessor="end"
        onSelectSlot={handleSelectSlot}
        onDoubleClickEvent={handleSelectEvent}
        selectable={!isDragging}
        views={["week", "month"]}
        defaultView={Views.WEEK}
        style={{ height: "calc(100% - 40px)" }}
        draggableAccessor={() => true}
        onEventDrop={handleEventDrop}
        resizable
        onDragStart={() => {
          setIsDragging(true);
          return true;
        }}
        onDragEnd={() => {
          setIsDragging(false);
        }}
      />
      <CalendarModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setNewEvent(emptyEvent);
          setSelectedEvent(null);
        }}
        newEvent={newEvent}
        setNewEvent={setNewEvent}
        onEventAdd={handleEventAdd}
        isEditing={!!selectedEvent}
      />
      {selectedEvent && (
        <EventDetailsModal
          open={showEventDetailsModal}
          onClose={() => {
            setShowEventDetailsModal(false);
            setSelectedEvent(null);
            setNewEvent(emptyEvent);
          }}
          event={selectedEvent}
          onEdit={handleEditEvent}
          onDelete={handleDeleteEvent}
        />
      )}
    </div>
  );
};

export default CalendarPage;
