import { db, storage } from "setup/firebase.js";
import {
  collection,
  doc,
  setDoc,
  query,
  getDocs,
  orderBy,
  limit,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import {
  listAll,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  updateMetadata,
  getMetadata,
} from "firebase/storage";
import React, { useState } from "react";
import moment from "moment";

export const createFolder = (folderName) => {
  const dummyFileName = "dummy.txt";
  const subfolders = [
    "Service Level Agreement",
    "Communication",
    "LearnerData",
  ]; // Fixed typo in 'Communication'
  const promises = [];

  subfolders.forEach((subfolder) => {
    const folderPath = `${folderName}/${subfolder}/${dummyFileName}`;
    const storageRef = ref(storage, folderPath);
    const blob = new Blob(["This is a dummy file"], { type: "text/plain" });
    const promise = uploadBytes(storageRef, blob)
      .then((snapshot) => {
        console.log(
          `Dummy file uploaded to ${subfolder} to simulate folder creation`
        );
      })
      .catch((error) => {
        console.error(`Error uploading dummy file to ${subfolder}:`, error);
        throw error;
      });
    promises.push(promise);
  });

  return Promise.all(promises)
    .then(() => {
      console.log("All dummy files uploaded successfully");
    })
    .catch((error) => {
      console.error("Error occurred while creating folders:", error);
    });
};

export const handleFileUploadLer = async (
  event,
  vendorId,
  learnerId,
  folderName,
  setFilesFunction,
  currentUser // Make sure this parameter is included
) => {
  console.log("Current user in handleFileUploadLer:", currentUser);

  const file = event.target.files[0];
  if (!file) return;

  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const folderPath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/${folderName}/${file.name}`;
  const fileRef = ref(storage, folderPath);

  try {
    await uploadBytes(fileRef, file);

    // Add metadata to the file
    const metadata = {
      customMetadata: {
        uploadDate: new Date().toISOString(),
        uploaderEmail: currentUser?.email || "Unknown",
      },
    };
    console.log("Metadata being set:", metadata);
    await updateMetadata(fileRef, metadata);

    const updatedFiles = await fetchFilesFromFolder(
      cleanedVendorId,
      cleanedLearnerId,
      folderName
    );
    setFilesFunction(updatedFiles);
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

export const saveVendorDetailsToDB = async (cleanedVendorId, vendorDetails) => {
  const vendorRef = doc(db, "vendors", cleanedVendorId); // Use the cleanedVendorId directly
  await setDoc(vendorRef, vendorDetails, { merge: true }); // Use merge option to update existing fields
};

export const fetchServiceLevelAgreementFiles = async (
  vendorFolder,
  folderName
) => {
  const folderPath = `${vendorFolder}/${folderName}`;
  const folderRef = ref(storage, folderPath);

  try {
    const fileList = await listAll(folderRef);
    const filePromises = fileList.items.map(async (fileRef) => {
      if (fileRef.name !== "dummy.txt") {
        try {
          const url = await getDownloadURL(fileRef);
          const metadata = await getMetadata(fileRef);
          return {
            name: fileRef.name,
            url,
            uploadDate: metadata.customMetadata?.uploadDate || "Unknown",
            uploaderEmail: metadata.customMetadata?.uploaderEmail || "Unknown",
          };
        } catch (error) {
          console.error(
            `Error fetching details for file ${fileRef.name}:`,
            error
          );
          return null;
        }
      }
      return null;
    });

    const files = await Promise.all(filePromises);
    return files.filter((file) => file !== null); // Remove any null entries
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error;
  }
};
// jan

export const writeLearnerData = async (vendorId, learnerDetails) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  try {
    // Firestore data writing logic remains the same
    const vendorsCol = collection(db, "vendors");
    const vendorDoc = doc(vendorsCol, cleanedVendorId);
    const limsCol = collection(vendorDoc, "LMIS");

    const q = query(limsCol, orderBy("docId", "desc"), limit(1));
    const querySnapshot = await getDocs(q);
    let newDocId = "001";
    if (!querySnapshot.empty) {
      const lastDoc = querySnapshot.docs[0];
      const lastDocId = lastDoc.data().docId;
      const incrementedId = parseInt(lastDocId) + 1;
      newDocId = incrementedId.toString().padStart(3, "0");
    }

    const newLimsDoc = doc(limsCol, newDocId);
    await setDoc(newLimsDoc, { ...learnerDetails, docId: newDocId });

    // New: Create only the specific subfolders under the existing 'LearnerData' folder
    const basePath = `${cleanedVendorId}/LearnerData/${newDocId}`;
    const subfolders = ["IDdoc", "Assessments", "Certificates", "CV"];
    const dummyFileContent = new Blob([""], { type: "text/plain" });

    for (const subfolder of subfolders) {
      const folderPath = `${basePath}/${subfolder}/dummy.txt`; // Use a dummy file to "create" the folder
      const storageRef = ref(storage, folderPath);
      await uploadBytes(storageRef, dummyFileContent);
    }

    console.log(
      "Document successfully written and necessary folders created with ID:",
      newDocId
    );
  } catch (error) {
    console.error(
      `Error in processing for vendorID: ${cleanedVendorId}`,
      error
    );
    throw error;
  }
};

export const fetchFilesFromFolder = async (
  vendorFolder,
  learnerFolder,
  subfolder
) => {
  const folderPath = `${vendorFolder.replace(
    "EMP",
    ""
  )}/LearnerData/${learnerFolder.replace("EMP", "")}/${subfolder}`;
  const folderRef = ref(storage, folderPath);

  try {
    const fileList = await listAll(folderRef);
    const filePromises = fileList.items.map(async (fileRef) => {
      if (fileRef.name !== "dummy.txt") {
        try {
          const url = await getDownloadURL(fileRef);
          const metadata = await getMetadata(fileRef);
          return {
            name: fileRef.name,
            url,
            uploadDate: metadata.customMetadata?.uploadDate || "Unknown",
            uploaderEmail: metadata.customMetadata?.uploaderEmail || "Unknown",
          };
        } catch (error) {
          console.error(
            `Error fetching details for file ${fileRef.name}:`,
            error
          );
          return null;
        }
      }
      return null;
    });

    const files = await Promise.all(filePromises);
    return files.filter((file) => file !== null); // Remove any null entries
  } catch (error) {
    console.error(`Error fetching files from ${subfolder}:`, error);
    throw error;
  }
};
// Communication

export const handleFileUpload = async (
  event,
  vendorFolder,
  setFilesFunction,
  folderName,
  setUploadModalOpen,
  setUploadModalMessage,
  currentUser // Add this parameter
) => {
  const file = event.target.files[0];
  if (!file) return;

  setUploadModalOpen(true);
  setUploadModalMessage("Uploading document...");

  const folderPath = `${vendorFolder}/${folderName}/${file.name}`;
  const fileRef = ref(storage, folderPath);

  try {
    await uploadBytes(fileRef, file);

    // Add metadata to the file
    const metadata = {
      customMetadata: {
        uploadDate: new Date().toISOString(),
        uploaderEmail: currentUser?.email || "Unknown",
      },
    };
    await updateMetadata(fileRef, metadata);

    const updatedFiles = await fetchServiceLevelAgreementFiles(
      vendorFolder,
      folderName
    );
    setFilesFunction(updatedFiles);
    setUploadModalMessage("Document uploaded successfully!");
    setTimeout(() => setUploadModalOpen(false), 2000);
  } catch (error) {
    console.error("Error uploading file:", error);
    setUploadModalMessage(`Error uploading document: ${error.message}`);
    setTimeout(() => setUploadModalOpen(false), 2000);
  }
};

export const fetchAssessmentFolders = async (vendorId, learnerId) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const assessmentsFolderPath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/Assessments`;
  const assessmentsFolderRef = ref(storage, assessmentsFolderPath);

  try {
    const folderList = await listAll(assessmentsFolderRef);
    const folderPromises = folderList.prefixes.map(async (folderRef) => {
      try {
        return { name: folderRef.name };
      } catch (error) {
        console.error(`Error processing folder ${folderRef.name}:`, error);
        return null;
      }
    });

    const folders = await Promise.all(folderPromises);
    return folders.filter((folder) => folder !== null);
  } catch (error) {
    console.error(`Error fetching assessment folders:`, error);
    return []; // Return an empty array instead of throwing an error
  }
};

export const createFolderInAssessments = async (
  vendorId,
  learnerId,
  folderName
) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const folderPath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/Assessments/${folderName}/dummy.txt`; // Using a dummy file to create the folder
  const folderRef = ref(storage, folderPath);
  const blob = new Blob(["This is a dummy file for folder creation"], {
    type: "text/plain",
  });
  try {
    await uploadBytes(folderRef, blob);
    console.log(`Folder created: ${folderName}`);
  } catch (error) {
    console.error(`Error creating folder ${folderName}:`, error);
    throw error;
  }
};

export const fetchFilesFromAssessmentFolder = async (
  vendorId,
  learnerId,
  assessmentFolderName
) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const folderPath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/Assessments/${assessmentFolderName}`;
  const folderRef = ref(storage, folderPath);

  try {
    const fileList = await listAll(folderRef);
    const filePromises = fileList.items
      .map(async (fileRef) => {
        if (fileRef.name !== "dummy.txt") {
          const url = await getDownloadURL(fileRef);
          const metadata = await getMetadata(fileRef);
          return {
            name: fileRef.name,
            url,
            uploadDate: metadata.customMetadata?.uploadDate || "Unknown",
            uploaderEmail: metadata.customMetadata?.uploaderEmail || "Unknown",
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    return Promise.all(filePromises);
  } catch (error) {
    console.error(
      `Error fetching files from assessment folder ${assessmentFolderName}:`,
      error
    );
    throw error;
  }
};

export const uploadDocumentToFolder = async (
  event,
  vendorId,
  learnerId,
  folderType,
  folderName,
  currentUser // Make sure this parameter is being passed
) => {
  const file = event.target.files[0];
  if (!file) return null;

  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const folderPath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/${folderType}/${folderName}/${file.name}`;
  const fileRef = ref(storage, folderPath);

  try {
    await uploadBytes(fileRef, file);
    const url = await getDownloadURL(fileRef);

    // Add metadata to the file
    const metadata = {
      customMetadata: {
        uploadDate: new Date().toISOString(),
        uploaderEmail: currentUser?.email || "Unknown",
      },
    };
    console.log("Metadata being set:", metadata);
    await updateMetadata(fileRef, metadata);

    console.log("File uploaded with metadata:", metadata);
    return {
      name: file.name,
      url,
      uploadDate: metadata.customMetadata.uploadDate,
      uploaderEmail: metadata.customMetadata.uploaderEmail,
    };
  } catch (error) {
    console.error("Error uploading document:", error);
    return null;
  }
};

export default async function AddUserToDB(userEmail, userType) {
  try {
    const docRef = doc(db, "Users", userEmail);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      const hh = await setDoc(doc(db, "Users", userEmail), {
        usertype: userType === "Admin" ? ["2001", "2009"] : ["2001"],
        userEmail: userEmail,
      });
      return hh === undefined ? "success" : "an Error has occured";
    } else {
      return "Email Already Exists";
    }
  } catch (error) {
    return error;
  }
}

export function DeleteUserFromDB(userEmail) {
  try {
    userEmail.forEach(async (element) => {
      try {
        await deleteDoc(doc(db, "Users", element));
      } catch (error) {
        console.log(error);
      }
    });
    return true;
  } catch (error) {
    return error;
  }
}

export const deleteFileFromFolder = async (
  vendorId,
  learnerId,
  folderName,
  fileName
) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const filePath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/${folderName}/${fileName}`;
  const fileRef = ref(storage, filePath);

  try {
    await deleteObject(fileRef);
    console.log(`File ${fileName} deleted successfully`);
    return true;
  } catch (error) {
    console.error(`Error deleting file ${fileName}:`, error);
    return false;
  }
};

export const deleteFolder = async (vendorId, learnerId, folderName) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const folderPath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/Assessments/${folderName}`;
  const folderRef = ref(storage, folderPath);

  try {
    const folderContent = await listAll(folderRef);
    const deletionPromises = folderContent.items.map((item) =>
      deleteObject(ref(storage, item.fullPath))
    );
    await Promise.all(deletionPromises);
    console.log(`Folder ${folderName} deleted successfully`);
    return true;
  } catch (error) {
    console.error(`Error deleting folder ${folderName}:`, error);
    return false;
  }
};

export const deleteAssessmentFile = async (
  vendorId,
  learnerId,
  assessmentFolderName,
  fileName
) => {
  if (!vendorId || !learnerId || !assessmentFolderName || !fileName) {
    console.error("Invalid parameters for file deletion");
    return false;
  }

  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");
  const filePath = `${cleanedVendorId}/LearnerData/${cleanedLearnerId}/Assessments/${assessmentFolderName}/${fileName}`;
  const fileRef = ref(storage, filePath);

  try {
    await deleteObject(fileRef);
    console.log(
      `File ${fileName} deleted successfully from ${assessmentFolderName}`
    );
    return true;
  } catch (error) {
    console.error(
      `Error deleting file ${fileName} from ${assessmentFolderName}:`,
      error
    );
    return false;
  }
};

export const deleteSLAOrCommFile = async (
  vendorFolder,
  folderName,
  fileName
) => {
  const filePath = `${vendorFolder}/${folderName}/${fileName}`;
  const fileRef = ref(storage, filePath);
  try {
    await deleteObject(fileRef);
    console.log(`File ${fileName} deleted successfully from ${folderName}`);
    return true;
  } catch (error) {
    console.error(`Error deleting file ${fileName} from ${folderName}:`, error);
    return false;
  }
};

export const updateLearnerDetails = async (vendorId, learnerId, updates) => {
  const cleanedVendorId = vendorId.replace("EMP", "");
  const cleanedLearnerId = learnerId.replace("EMP", "");

  const docRef = doc(db, "vendors", cleanedVendorId, "LMIS", cleanedLearnerId); // Use cleaned IDs here
  try {
    await updateDoc(docRef, updates);
    console.log("Learner details updated successfully");
    return true;
  } catch (error) {
    console.error("Error updating learner details:", error);
    return false;
  }
};

export const saveOrUpdateEvent = async (eventData) => {
  const eventsCol = collection(db, "events");

  const convertMomentToDate = (obj) => {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (moment.isMoment(value)) {
        newObj[key] = value.toDate();
      } else if (value instanceof Date) {
        newObj[key] = value;
      } else if (key === "email") {
        // Handle both string and array inputs for email
        if (typeof value === "string") {
          newObj[key] = value
            .split(";")
            .map((email) => email.trim())
            .filter((email) => email.length > 0);
        } else if (Array.isArray(value)) {
          newObj[key] = value.filter(
            (email) => email && email.trim().length > 0
          );
        } else {
          newObj[key] = [];
        }
      } else if (typeof value === "object" && value !== null) {
        newObj[key] = convertMomentToDate(value);
      } else {
        newObj[key] = value;
      }
    }
    return newObj;
  };

  const convertedEventData = convertMomentToDate(eventData);
  console.log("Converted event data:", convertedEventData);

  try {
    if (convertedEventData.id) {
      const eventRef = doc(eventsCol, convertedEventData.id);
      await updateDoc(eventRef, {
        ...convertedEventData,
        active: convertedEventData.active,
        updatedAt: new Date(),
      });
      console.log("Event updated successfully");
      return { ...convertedEventData, active: true };
    } else {
      const newEventData = {
        ...convertedEventData,
        active: true,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      const docRef = await addDoc(eventsCol, newEventData);
      console.log("New event added with ID: ", docRef.id);
      return { ...newEventData, id: docRef.id };
    }
  } catch (error) {
    console.error("Error saving/updating event:", error);
    throw error;
  }
};
