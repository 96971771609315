import { Box, Button } from "@mui/material";
import React from "react";
import "pages/LandingPage/LandingPage.scss";
import epsaconnectImage from "content/epsaconnect.jpg";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function LandingPage() {
  // const navigate = useNavigate();
  return (
    <div className="mainDiv">
      <Box className="logoBox">
        <img src={epsaconnectImage} alt="Circular" className="circular-image" />
      </Box>
      <Box className="boxSetup">
        <Box className="buttonBoxOuter">
          <Box className="buttonbox">
            <Button
              variant="contained"
              className="button"
              component={Link}
              to="/VendorList"
            >
              MY EMPLOYEE PORTAL
            </Button>
          </Box>
          <Box className="buttonbox">
            <Button variant="contained" className="button">
              DOWNLOAD FORMS
            </Button>
          </Box>
        </Box>
        <Box className="buttonBoxOuter">
          <Box className="buttonbox">
            <Button
              variant="contained"
              className="button"
              component={Link}
              to="/calender"
            >
              CALENDER
            </Button>
          </Box>
          <Box className="buttonbox">
            <Button variant="contained" className="button">
              Blank
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
