import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const EventDetailsModal = ({ open, onClose, event, onEdit, onDelete }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="event-details-modal-title"
      aria-describedby="event-details-modal-description">
      <Box sx={modalStyle}>
        <Typography
          id="event-details-modal-title"
          variant="h6"
          component="h2"
          gutterBottom>
          Event Details
        </Typography>
        <Typography variant="body1" gutterBottom>
          Title: {event.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Start: {event.start.toString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          End: {event.end.toString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Location: {event.location}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Emails:
        </Typography>
        <div
          className="email-list"
          style={{
            paddingLeft: "16px",
            maxHeight: "100px",
            overflowY: "auto",
          }}>
          {Array.isArray(event.email) ? (
            event.email.map((email, index) => (
              <Typography
                key={index}
                variant="body2"
                className="email-list-item">
                {email}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" className="email-list-item">
              • {event.email}
            </Typography>
          )}
        </div>
        <Typography variant="body1" gutterBottom>
          Repeat: {event.repeat}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Comment: {event.comment}
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <Button
            onClick={onEdit}
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}>
            Edit
          </Button>
          <Button onClick={onDelete} variant="contained" color="error">
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventDetailsModal;
