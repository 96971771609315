import {
  doc,
  updateDoc,
  getDoc,
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
// import firebaseApp from "./firebase";
import firebaseApp from "setup/firebase";
import moment from "moment";
const db = getFirestore(firebaseApp);

export function GetVendorList() {
  const [todos, setTodos] = useState([]);
  const fetchPost = async () => {
    await getDocs(collection(db, "vendors")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        id: "EMP" + doc.id,

        ...doc.data(),
      }));
      // console.log(newData);
      setTodos(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);
  return todos;
}

export async function FetchVendorData(vendorID) {
  if (!vendorID) {
    console.error("Invalid or missing email in fetchUserData");
    return null;
  }
  try {
    const docRef = doc(db, "vendors", vendorID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const newData = {
        // ID: docSnap.id,
        Company_Name: docSnap.data().Company_Name,
        Registration_Number: docSnap.data().Registration_Number,
        Vat_Number: docSnap.data().Vat_Number,
        Physical_Address: docSnap.data().Physical_Address,
        Postal_Adress: docSnap.data().Postal_Adress,
        Company_Contact_Number: docSnap.data().Company_Contact_Number,
        Company_Email_Adress: docSnap.data().Company_Email_Adress,
        Industry: docSnap.data().Industry,
        Contact_Person_Name: docSnap.data().Contact_Person_Name,
        Designation: docSnap.data().Designation,
        Contact_Number: docSnap.data().Contact_Number,
        Email_Adress: docSnap.data().Email_Adress,
        Financial_Department_Responsible_Person:
          docSnap.data().Financial_Department_Responsible_Person,
        Financial_Person_Contact_Number:
          docSnap.data().Financial_Person_Contact_Number,
        Financial_Person_Email_Adress:
          docSnap.data().Financial_Person_Email_Adress,
      };

      // companyName: "Green Fox PTY LTD 56456";
      // contactNumber: "0825676853";
      // designation: "Real Estate Company";
      // docId: "008";
      // emailAdress: "juron@nexor862.co.za";
      // finContactNumber: "0824676853";
      // finEmailAdress: "juron.maree@gmail.com";
      // finPerson: "Juron Maree";
      // physicalAdress: "144 Thabo Mbeki Way, Potchefstroom";
      // postalAdress: "Nexor Properties";
      // regNumber: "2022/44578522";
      // vatNumber: "17484187484";
      // console.log(newData);
      return newData;
    } else {
      console.error("User document does not exist in Firestore:", vendorID);
      return null;
    }
  } catch (error) {
    console.error("Error while fetching user data:", error);
    return null;
  }
}

export function GetVendorLMISList(vendorID) {
  const [limsRecords, setLimsRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchRecords = async () => {
    try {
      const cleanedVendorId = vendorID.replace("EMP", "");
      const lmisCollectionRef = collection(
        db,
        "vendors",
        cleanedVendorId,
        "LMIS"
      );
      const snapshot = await getDocs(lmisCollectionRef);
      const records = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLimsRecords(records);
      setError(null);
    } catch (err) {
      console.error("Error fetching LIMS records:", err);
      setError(err.message);
      setLimsRecords([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [vendorID]);
  return { limsRecords, loading, error };
}

export function GetVendorLMISDoc(vendorID, docID) {
  const [todo, setTodo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDocument = async () => {
    setLoading(true);
    try {
      // Specify the path to the specific document
      const docRef = doc(db, "vendors", vendorID.slice(3), "LMIS", docID);
      //   console.log(docRef);
      const docSnap = await getDoc(docRef);
      // console.log(vendorID.slice(3));
      // console.log(docID);
      // console.log(docSnap.data());

      if (docSnap.exists()) {
        const newData = {
          // ID: docSnap.data().id,
          Name: docSnap.data().Name,
          Surname: docSnap.data().Surname,
          Nationality: docSnap.data().Nationality,
          ID_Number: docSnap.data().ID_Number,
          Race: docSnap.data().Race,
          Occupation: docSnap.data().Occupation,
          Highest_Qualification: docSnap.data().Highest_Qualification,
          // Designation: docSnap.data().Designation,
          Contact_Number: docSnap.data().Contact_Number,
          Email_Address: docSnap.data().Email_Address,
          Work_Number: docSnap.data().Work_Number,
          Work_Email: docSnap.data().Work_Email,
          Disabilities: docSnap.data().Disabilities,
          // Company_Name: docSnap.data().CompanyName
          // Any other fields you want to include...
        };
        // console.log(newData);
        setTodo(newData);
        setError(null);
      } else {
        setError("Document does not exist");
        setTodo(null);
      }
    } catch (err) {
      setError(err.message);
      setTodo(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocument();
  }, [vendorID, docID]); // Re-run when vendorID or documentID changes
  //   console.log(todo);
  return { todo, loading, error }; // Return the single document, loading state, and any error
}

export async function DbManagementCall() {
  try {
    const docSnap = await getDocs(collection(db, "Users"));
    const newData = docSnap.docs.map((doc) => ({
      id: doc.id,
      userEmail: doc.data().userEmail,
      type: doc.data().usertype,
    }));
    return newData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export default async function emailAlreadyExists(email) {
  if (!email) {
    console.error("Invalid or missing email");
    return null;
  }

  try {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error("User document does not exist in Firestore:", email);
      return null;
    }
  } catch (error) {
    console.error("Error while fetching user data:", error);
    return null;
  }
}

export const fetchEvents = async () => {
  const eventsCol = collection(db, "events");
  const activeEventsQuery = query(eventsCol, where("active", "==", true));

  try {
    const querySnapshot = await getDocs(activeEventsQuery);
    const events = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      start: doc.data().start.toDate(),
      end: doc.data().end.toDate(),
    }));

    return generateRecurringEvents(events);
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

const generateRecurringEvents = (events) => {
  const expandedEvents = [];

  events.forEach((event) => {
    if (event.repeat === "never") {
      expandedEvents.push(event);
    } else {
      const startDate = moment(event.start);
      const endDate = moment(event.end);
      const duration = moment.duration(endDate.diff(startDate));

      // Generate events for the next year
      const recurrenceEnd = moment().add(1, "year");

      let currentDate = startDate.clone();

      while (currentDate.isBefore(recurrenceEnd)) {
        expandedEvents.push({
          ...event,
          start: currentDate.toDate(),
          end: currentDate.clone().add(duration).toDate(),
          isRecurrence: currentDate.isAfter(startDate),
        });

        switch (event.repeat) {
          case "daily":
            currentDate.add(1, "day");
            break;
          case "weekly":
            currentDate.add(1, "week");
            break;
          case "monthly":
            currentDate.add(1, "month");
            break;
          case "yearly":
            currentDate.add(1, "year");
            break;
        }
      }
    }
  });
  console.log(expandedEvents);
  return expandedEvents;
};
